<template>
  <div class="user-list">
    <h1>Mercala Users</h1>

    <div class="user-list-tools">
      <TextInput
        placeholder="Search email"
        inputName="search"
        inputType="text"
        inputClass="dashboard"
        v-model="searchInput"
      />
    </div>

    <div class="user-list-table">
      <table>
        <thead>
          <tr>
            <th
              v-for="(row, index) in table.headers"
              :key="index"
              v-on:click="setSorting(row.sorting)"
            >
              {{ row.title }}
              <img
                v-if="row.title != ''"
                src="../../../assets/images/sorting_arrow.svg"
                :class="showSorting(row.sorting)"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="index">
            <td>{{ user.email }}</td>
            <td>{{ formatReadableDate(user.created) }}</td>
            <td>{{ user.status }}</td>
            <td>
              <span
                class="company-kvk"
                v-on:click="updateProfileKvkStatus(user)"
              >
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  v-if="user.profile.company_kvk_valid"
                />
              </span>
            </td>
            <td>
              <span class="subscription-payment">
                <font-awesome-icon
                  :icon="getSubscription(user.subscriptions)"
                />
              </span>
            </td>
            <td class="tools">
              <span v-on:click="openSubscriptions(user)">
                <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
              </span>
              <span v-on:click="openUserProfile(user)">
                <font-awesome-icon :icon="['fas', 'address-card']" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Lightboxes } from "@/utils/Constants";
import Helpers from "@/utils/Helpers";
import AngelaAPI from "@/utils/AngelaAPI";
import { EventBus } from "@/utils/EventBus.js";
const moment = require("moment");

export default {
  name: "AdminUserList",
  components: {
    TextInput: () => import("@/components/form/TextInput"),
  },
  data() {
    return {
      users: [],
      showLightbox: false,
      currentLightbox: -1,
      currentSelectedUser: null,
      searchInput: "",
      searchDelay: null,
      table: {
        headers: [
          { title: "EMAIL", sorting: "email" },
          { title: "CREATED", sorting: "inserted_at" },
          { title: "STATUS", sorting: "status" },
          { title: "VALID KVK", sorting: "valid_kvk" },
          { title: "ALL PAID", sorting: "all_paid" },
          { title: "TOOLS", sorting: "tools" },
        ],
      },
    };
  },
  watch: {
    searchInput(val) {
      clearTimeout(this.searchDelay);
      this.searchDelay = setTimeout(() => {
        this.fetchUsers();
      }, 1000);
    },
  },
  methods: {
    onCloseLightbox() {
      this.showLightbox = false;
    },
    updateProfileKvkStatus(user) {
      const status = !user.profile.company_kvk_valid;
      AngelaAPI.updateProfileKvkStatus(user.id, status)
        .then((response) => {
          this.fetchUsers();
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    openUserProfile(user) {
      this.currentLightbox = Lightboxes.AdminUserProfile;
      EventBus.$emit("open-lightbox", {
        view: Lightboxes.AdminUserProfile,
        data: {
          user: user,
        },
      });
      this.showLightbox = true;
    },
    openSubscriptions(user) {
      this.currentLightbox = Lightboxes.Subscriptions;
      this.currentSelectedUser = user;
      EventBus.$emit("open-lightbox", {
        view: Lightboxes.Subscriptions,
        data: {
          user: user,
        },
      });
      this.showLightbox = true;
    },
    formatReadableDate(datetime) {
      return moment(datetime).format("DD-MM-YYYY HH:mm");
    },
    setSorting(field) {
      Helpers.setSorting(this, field);
      this.fetchUsers();
    },
    fetchUsers() {
      const filters = this.$store.getters.getFormValues("appliedFilters");
      filters.search = this.searchInput;
      filters.sorting = this.$store.getters.getFormValues("sorting");

      AngelaAPI.listUsers(filters)
        .then((response) => {
          this.users = response.data.data;

          // Update lightbox
          if (
            this.showLightbox &&
            this.currentLightbox === Lightboxes.Subscriptions
          ) {
            const updatedUser = this.users.find((user) => {
              return user.id === this.currentSelectedUser.id;
            });
            this.openSubscriptions(updatedUser);
          }
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    getSubscription(subscriptions) {
      const needPayment = subscriptions.find((sub) => {
        return sub.status === "pending";
      });

      if (needPayment) {
        return ["fas", "times-circle"];
      }

      return ["fas", "check-circle"];
    },
    // CLASSES
    showSorting(field) {
      return Helpers.showSorting(this, field);
    },
  },
  mounted() {
    this.fetchUsers();
    EventBus.$on("refresh-users", () => {
      this.fetchUsers();
    });
    EventBus.$on("close-lightbox", () => {
      this.onCloseLightbox();
    });
  },
  destroyed() {
    EventBus.$off("refresh-users", () => {
      this.fetchUsers();
    });
    EventBus.$off("close-lightbox", () => {
      this.onCloseLightbox();
    });
  },
};
</script>

<style lang="scss">
.user-list {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 25px;

  h1 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 25px;
  }

  &-tools {
    margin-bottom: 25px;
  }

  &-table {
    width: 100%;
    table {
      width: 100%;
      thead {
        cursor: pointer;
        position: relative;
        padding-bottom: 15px;
        padding-right: 15px;

        tr {
          th {
            color: #979797;
            font-size: 0.625rem;
            font-weight: 300;

            img {
              margin: 0px 0px 3px 2px;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 0px;
            border-bottom: 1px solid #d8d8d8;
            font-size: 0.8rem;

            span {
              &:hover {
                cursor: pointer;
                svg {
                  path {
                    fill: red;
                  }
                }
              }
            }

            &.tools {
              span {
                margin-right: 10px;

                svg {
                  font-size: 1.4rem;
                }

                &:last-child {
                  margin-right: 0px;
                }
              }
            }

            .company-kvk {
              position: relative;
              width: 20px;
              height: 20px;
              border: 2px solid black;
              border-radius: 3px;
              display: inline-block;
              font-size: 30px;

              svg.fa-check {
                position: absolute;
                top: -8px;
                left: 1px;
                font-size: 1.6rem;
                path {
                  fill: #50ae22;
                }
              }
            }

            .subscription-payment {
              svg.fa-times-circle {
                font-size: 1.4rem;
                path {
                  fill: red;
                }
              }

              svg.fa-check-circle {
                font-size: 1.4rem;
                path {
                  fill: #50ae22;
                }
              }

              &:hover {
                cursor: default;
              }
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              text-align: center;
            }
          }
        }
      }
    }

    .table-sorting {
      display: none;

      &-show {
        display: inline-block;
      }

      &-asc {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
